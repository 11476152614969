@import "./variable.scss";

.event-icon {
  color: $orange_1;
}

.schedule-icon {
  color: $orange_1;
}
.batch-card{
   width: 100%;
    height: 100%;
}
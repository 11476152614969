@import "./variable.scss";

.CarouselItem {
  position: relative;
}

.CarouselItem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $blue_20;
  opacity: 0.4;
  z-index: 1;
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
  background-color: $white_1;
}

.custom-carousel .carousel-control-prev {
  left: 0;
}

.custom-carousel .carousel-control-next {
  right: 0;
  left: auto;
}

.hover-shift-right:hover {
  padding-left: 20px;
}

@import "./variable.scss";

.chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $blue_3;
  padding: 10px;
  color: $white_1;

  .status {
    font-size: 0.5rem;
  }

  .close-button {
    background-color: transparent;
    border: none;
    color: $white_1;
    cursor: pointer;
  }
}

@import "./variable.scss";

.list-item {
  padding-left: 1rem;
}

.cip {
  font-size: 1.3125rem !important; 
  text-align: center;

  @media (min-width: 600px) {
    font-size: 1.875rem !important; 
  }

  @media (min-width: 960px) {
    font-size: 1.875rem !important; 
  }

  @media (min-width: 1280px) {
    font-size: 2rem !important; 
  }
}


.eligibility-criteria {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 2%;
}

.eligibility-item {
  flex: 1 1 calc(50% - 16px);
  min-width: 0;
}

@media (max-width: 1024px) {
  .eligibility-item {
    flex: 1 1 100%;
  }
}



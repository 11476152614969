@import "./variable.scss";

.footerLink:hover {
  color: $blue_19 !important;
}

.text-color {
  color: $gray_8 !important;
}

.copyright-text {
  background-color: $gray_9 !important;
}

.dsedify-logo {
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    width: 40%;  
    height: auto;
    margin: 4% 0 -6% 0;
  }
}


.footer-icons:hover {
  filter: drop-shadow(0px 0px 8px $white_1);
}

.icons-border {
  border-top: 0.1px solid $gray_8;
}

.footer-link {
  text-decoration: none;
}
.custom-footer-box {
  width: 98vw;
  left: 1%;
  top: -10%;

  @media (min-width: 600px) {
    width: 84vw;
    left: 8%;
    top: -15%;
  }
  @media (min-width: 375px) {
    width: 84vw;
    left: 8%;
  }
  @media (min-width: 768px) {
    width: 84vw;
    left: 8%;
    top: -8%;
  }

  @media (min-width: 960px) {
    width: 84vw;
    left: 8%;
    top: -10%;
  }

  @media (min-width: 1280px) {
    width: 82vw;
    left: 9%;
    top: -10%;
  }
}

@import "./variable.scss";

.home-vission {
  border-radius: 10px !important;
}

.about-us {
  height: 100%;
}

.about-desc {
  padding-left: 1rem !important;
}

.achive-cont {
  max-height: 40vh !important;
  border-radius: 10px !important;
}

.achive-card {
  width: 40% !important;
  height: 40% !important;
}

.achive-crds {
  text-align: center;
  font-weight: 900 !important;
}

@media (min-width: 425px) {
  .achive-crds {
    font-size: 1.25rem !important;
  }
}

@media (min-width: 768px) {
  .achive-crds {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 1024px) {
  .achive-crds {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 1440px) {
  .achive-crds {
    font-size: 2.0625rem !important;
  }
}

.our-popular-prog {
  background-color: $blue_1;
}

.our-latest-news {
  padding-bottom: 40% !important;
}

@media (min-width: 600px) {
  .our-latest-news {
    padding-bottom: 10% !important;
  }
}

@media (min-width: 960px) {
  .our-latest-news {
    padding-bottom: 10% !important;
  }
}

.our-latest-card {
  overflow-y: auto;
}

.our-batches {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-text {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.truncate-text-twoline {
  -webkit-line-clamp: 1;
}

.activement-section {
  border: 1px solid $blue_17;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 10px;
  height: 20vh;
  text-decoration: none;
}

.upcoming-batch {
  margin-top: 15%;

  @media (min-width: 600px) {
    margin-top: -18% !important;
  }

  @media (min-width: 375px) {
    margin-top: 8% !important;
  }

  @media (min-width: 425px) {
    margin-top: 15% !important;
  }

  @media (min-width: 738px) {
    margin-top: -17% !important;
  }

  @media (min-width: 960px) {
    margin-top: -10% !important;
  }

  @media (min-width: 1280px) {
    margin-top: 0 !important;
  }
}

.collabration-clg {
  font-size: 1.25rem;
  font-weight: 800;
  color: $gray_10;
}

@media (max-width: 320px) {
  .collabration-clg {
    font-size: 1rem !important;
  }
}

.card-image {
  width: 100%;
  height: 80%;
  object-fit: fill;
}

.core-image {
  width: 70%;
  object-fit: fill;
}

.about-Img {
  width: 80%;
}

.actionarea {
  &.with-border {
    border-right: 0.375rem solid $orange_2;
  }
}

.event-card-btn {
  background-color: $primary !important;
  color: $white_1 !important;
  margin-right: auto !important;

  &:hover {
    background-color: $blue_3 !important;
  }
}

.join-event {
  cursor: pointer !important;
  color: $blue_4 !important;
}

.latest-mediacard {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.whatpeople-scrollbar {
  overflow-y: auto;
  max-height: 14vh;

  @media (min-width: 600px) {
    max-height: 7vh;
  }

  @media (min-width: 960px) {
    max-height: 10vh;
  }

  @media (min-width: 1280px) {
    max-height: 9vh;
  }

  &::-webkit-scrollbar {
    display: block;
    background-color: $white_3;
    width: 0.3125rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black_4;
  }
}

.star-quote {
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }

  align-items: center;
  flex-direction: row;
  gap: 16px;
  overflow: hidden;
  flex-wrap: nowrap;

  img {
    height: 1.25rem;
    width: auto;
  }
}

.style-card {
  display: flex;
  gap: 16px;

  margin-bottom: 16px;

  @media (min-width: 600px) {
    margin-bottom: 0;
  }
}

.subscribe-head {

  font-size: 20px !important;

  @media (min-width: 600px) {
    font-size: 20px !important;
  }

  @media (min-width: 900px) {
    font-size: 1.75rem !important;
  }

  @media (min-width: 1200px) {
    font-size: 30px !important;
  }
}

.product-head {
  color: $white_1;

  @media (max-width: 599px) {
    font-size: 20px !important;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    font-size: 32px !important;
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    font-size: 17px !important;
  }

  @media (min-width: 1200px) {
    font-size: 24px !important;
  }
}

@keyframes rotate {
  from {
    transform: rotateY(180deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.rotate-360 {
  animation: rotate 0.8s ease-out;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  will-change: transform;
}
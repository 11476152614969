@import "./variable.scss";

.footerText {
  color: $primary !important;
}

.darkBlueText {
  color: $primary !important;
  opacity: 80%;
}

.darkBlueTextHeading {
  color: $primary;
}

.darkBlueBackground {
  background-color: $primary;
}

.bgcolor {
  background-color: $blue_1;
}
.bgcolor {
  background-color: $primary;
}
.bgcolor {
  background-color: $primary;
}
.bgcolor {
  background-color: $blue_2;
}

.carouselBtnColor {
  color: $blue_3 !important;
}
.carouselBtnColor {
  color: $blue_2 !important;
}
.carouselBtnColor {
  color: $orange_1 !important;
}

.chipClr {
  color: $blue_3 !important;
}

.sectionHeadingColor {
  color: $primary !important;
  opacity: 20%;
}

.productsTextDescription {
  color: $blue_4 !important;
  opacity: 80%;
}

.orangeColor {
  color: $orange_2 !important;
}

.campus-prog-text {
  color: $white_1;
  opacity: 80%;
}

.error-message {
  margin-top: 5px;
  color: $red_1;
  font-size: small;
}

.textclr {
  color: $white_1 !important;
}

.gradient-border {
  background: linear-gradient(
    90deg,
    $orange_3 0%,
    $gray_1 50%,
    $blue_3 100%
  ) !important;
  border-image-slice: 1;
}

@import "./variable.scss";

.popOver {
  position: relative;
  background: $white_1;
  border-radius: 0.4em;
  width: 100%;
  padding: 7%;
  color: $primary;
  margin: 30px 0px;
  overflow: visible;

  @media (max-width: 1023px) {
    height: auto;
    padding: 5%;
  }

  @media (min-width: 1024px) {
    height: 260px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -19px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 20px 20px 20px;
    border-style: solid;
    border-color: transparent transparent $white_1 transparent;
    display: block;
    width: 0;
  }

  .popOver-content {
    max-height: 240px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 15px;
      background-color: $white_1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black_4;
      border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.1) $white_1;
  }
}

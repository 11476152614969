@import "./variable.scss";

@media (max-width: 768px) {
  .fs-md-45 {
    font-size: 2.8125rem;
  }
  .fs-xs-26 {
    font-size: 1.625rem;
  }
}

@media (min-width: 768px) {
  .fs-45 {
    font-size: 2.8125rem;
  }
}

.closeicon-color {
  color: $black_1;
}

@import "./variable.scss";

.stepper-container {
  display: flex;
  position: relative;
}

.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  margin: 0% 1%;
  width: 100%;

  &:not(:last-child) {
    margin-right: -2%;
  }

  .stepper-number {
    color: $white_1;
    background-color: $ternary;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
  }

  .stepper-heading {
    font-size: 1rem;
    font-weight: 600;
    color: $blue_4;
    margin-bottom: 10px;
    text-align: center;
    border: 1px solid $secondary;
    background-color: $pink_1;
    border-radius: 12px;
    padding: 3px 8px;
    width: 100% !important;

    @media (max-width: 1200px) {
      font-size: 0.8125rem;
    }
  }

  .stepper-description {
    font-size: 1rem  !important;
    font-weight: 500;
    color: $blue_4;
    text-align: start;
  }

  .stepper-connector {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: $blue_21;
    z-index: 0;
  }

  &:first-child .stepper-connector {
    left: 59%;
  }

  &:last-child .stepper-connector {
    display: none;
  }
}

@media (max-width: 1200px) {
  .stepper-item {
    margin: 0% 0.5%;
  }

  .stepper-heading {
    font-size: 0.875rem;
  }

  .stepper-description {
    font-size: 0.875rem !important;
  }
}

@media (max-width: 992px) {
  .stepper-container {
    flex-direction: column;
    align-items: center;
  }

  .stepper-item {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
    }

    .stepper-connector {
      display: none;
    }
  }

  .stepper-number {
    margin-bottom: 10px;
  }

  .stepper-heading,
  .stepper-description {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .stepper-item {
    margin-bottom: 5px;
    width: 100%;
  }
}

@import "./variable.scss";

.nav-res-links {
  $white_1-space: nowrap;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    display: none;
  }
}

.nav-logo {
  flex-grow: 1;

  @media (min-width: 768px) {
    flex-grow: 0;
  }
}

.link-styles {
  display: flex;
  align-items: center;
}
.nav-btns {
  display: none;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 10px;

  @media (min-width: 1200px) {
    display: flex;
  }
}

.nav-icon-btn {
  @media (min-width: 0px) {
    display: block;
  }

  @media (min-width: 600px) {
    display: block;
  }

  @media (min-width: 1200px) {
    display: none !important;
  }
}

.nav-close-icon {
  padding: 16px;
  height: 100%;
  background-color: $white_1;
}

.nav-link-page {
  $white_1-space: nowrap;
  flex-grow: 1;
  display: block;

  @media (min-width: 600px) {
    display: none;
  }

  justify-content: center;
  flex-wrap: wrap;
}

.nav-link {
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .nav-res-links,
  .nav-btns {
    display: none;
  }

  .nav-icon-btn {
    display: inline-flex;
  }

  .nav-link-page {
    display: block;
  }
}

@media (min-width: 993px) {
  .nav-icon-btn {
    display: none;
  }
}

.nav-item {
  flex-shrink: 0;
}

.navbar-event {
  position: absolute;
  bottom: -25% !important;
  width: 50% !important;
  display: flex;
  justify-content: end;
  align-items: center;
}
.nav-event-dot {
  margin: 0px;
  display: flex;
  justify-content: end;
}

.navbar-main {
  top: 0 !important;
  position: sticky !important;
  z-index: 1200 !important;
}
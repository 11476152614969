.about-card-media {
  height: 100px;
  object-fit: cover;
  padding: 5px;
  border-radius: 10px;
}

.about-carrear-card {
  width: 220px;
  height: 160px;
  border-radius: 8px !important;
}

.carousel-main {
  margin-top: -5px;
}

.carousel-Caption {
  bottom: 0;
  z-index: 2;
  padding-left: 1rem;  
  padding-right: 1rem;  

  @media (min-width: 600px) {
    padding-left: 2rem;  
    padding-right: 2rem;  
  }

  @media (min-width: 960px) {
    padding-left: 3rem;  
    padding-right: 3rem;  
  }
}

.about-content {
  z-index: 3;
  margin-bottom: 15%;
  margin-left: -8%;

  @media (max-width: 960px) {
    margin-bottom: 12%;
    margin-left: -5%;
  }

  @media (max-width: 600px) {
    margin-bottom: 10%;
    margin-left: -5%;
  }
}

.aboutus-contents {
  width: 100% !important;
  text-align: left !important;
  font-size: 0.875rem;  

  @media (min-width: 600px) {
    width: 70% !important;
    font-size: 1rem;  
  }

  @media (min-width: 900px) {
    width: 60% !important;
    font-size: 1.125rem;  
  }
}

.campus-main {
  z-index: 3;
  margin-left: -8%;

  @media (min-width: 960px) {
    margin-bottom: 15%;
  }
}

.carousel-title {
  text-align: left;

  font-size: 1.875rem !important;  

  @media (min-width: 600px) {
    font-size: 3.125rem !important;  
  }

  @media (min-width: 960px) {
    font-size: 4.375rem !important;  
  }
}

.campus-contents {
  text-align: left;

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (min-width: 600px) {
    width: 70%;
  }
}

.offcampus-main {
  z-index: 3;
  position: relative;

  @media (max-width: 600px) {
    margin-bottom: 20%;
    margin-left: -5%;
    margin-top: 35%;
  }

  @media (min-width: 600px) and (max-width: 960px) {
    margin-bottom: 15%;
    margin-left: -8%;
    margin-top: -2%;
  }

  @media (min-width: 960px) and (max-width: 1280px) {
    margin-bottom: 10%;
    margin-left: -10%;
    margin-top: -10%;
  }

  @media (min-width: 1280px) {
    margin-top: -10%;
  }
}

.corporate-main {
  z-index: 3;
  margin-bottom: 15%;
  margin-left: -8%;
  margin-top: 7%;

  @media (min-width: 768px) {
    margin-bottom: 15%;
  }

  @media (min-width: 1024px) {
    margin-top: 7%;
  }
}

.cpp-main {
  z-index: 3;
  margin-bottom: 15%;
  margin-left: -8%;

  @media (min-width: 768px) {
    margin-bottom: 15%;
  }
}

.cpp-title {
  text-align: left;

  font-size: 1.4375rem !important;  

  @media (min-width: 600px) {
    font-size: 2.1875rem !important;  
  }

  @media (min-width: 960px) {
    font-size: 3.125rem !important;  
  }
}

.pdp-main {
  z-index: 3;
  margin-bottom: 15% !important;
  margin-left: -8%;
  font-size: 1.4375rem !important;  


  @media (max-width: 600px) {
    margin-top: -2%;
  }

  @media (min-width: 600px) {
    margin-top: 10% !important;
  }

  @media (min-width: 960px) {
    margin-top: 10% !important;
  }

  @media (min-width: 1280px) {
    margin-top: 10%;
  }
}

.pdp-title {
  text-align: left;

  font-size: 22px !important;

  @media (min-width: 600px) {
    font-size: 35px !important;
  }

  @media (min-width: 960px) {
    font-size: 50px !important;
  }
}

.elip-main {
  z-index: 3;
  margin-bottom: 15%;
  margin-left: -8%;

  @media (min-width: 768px) {
    margin-bottom: 15%;
  }
}

.cip-main {
  z-index: 3;
  margin-bottom: 15%;

  @media (max-width: 900px) {
    margin-left: -8% !important;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    margin-top: -2% !important;
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    margin-top: 10% !important;
  }

  @media (min-width: 1200px) {
    margin-top: 10% !important;
  }
}

.cip-title {
  text-align: left;
  font-size: 1.375rem !important;  

  @media (min-width: 600px) {
    font-size: 2.5rem !important;  
  }

  @media (min-width: 960px) {
    font-size: 4.0625rem !important;  
  }
}

.cpp-contents {
  width: 100%;

  text-align: left;
  font-size: 0.90625rem !important;  

  @media (min-width: 600px) {
    width: 70%;
    font-size: 1.125rem !important;  
  }
}

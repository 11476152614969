@import "./variable.scss";

$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;

.corporate-page-title {
  color: $white_2;
  font-size: 2.8125rem !important;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: 3.75rem !important;
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: 3.0625rem !important;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 4.375rem !important;
  }
}

@import "./variable.scss";

.website-scroll-bar::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  border-radius: 13px !important;
}

.website-scroll-bar::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 13px !important;
}

.website-scroll-bar::-webkit-scrollbar-thumb {
  background-color: $blue_6 !important;
  border-radius: 13px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.hr {
  border: 1px solid;
  border-style: dashed;
}

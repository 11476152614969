@import "./variable.scss";

$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;

.career-page-title {
  color: $white_2;
  font-size: 2.8125rem;

  @media screen and (min-width: $breakpoint-xs) {
    font-size: 3.75rem !important; 
  }
  @media screen and (min-width: $breakpoint-sm) {
    font-size: 3.75rem !important; 
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: 3.625rem !important; 
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 4.6875rem !important; 
  }
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding-responsive {
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.careers-img {
  margin-top: -0.5%;
}

.resume-text {
  color: $blue_4;
}

.resume-error {
  color: $red_2 !important;
  margin-left: 0px !important;
}

.text-align {
  text-align: center;

  @media (min-width: 600px) {
    text-align: left;
  }
}

.list-style-square {
  list-style-type: square;
}

.teamcard-img {
  display: flex;
  justify-content: center;

  @media (max-width: 576px) {
    display: none !important;
  }

  @media (min-width: 576px) {
    display: block !important;
  }
}

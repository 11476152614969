.footer {
  margin-bottom: 35%;

  @media (min-width: 600px) {
    margin-bottom: 18%;
  }

  @media (min-width: 900px) {
    margin-bottom: 10%;
  }

  @media (min-width: 1200px) {
    margin-bottom: 10%;
  }
}

@import "./_variable.scss";

.faq-height {
  height: calc(100vh - 80px);
}

.faq-accordion-height {
  height: 36vh;
}

@media (min-width: 768px) {
  .faq-accordion-height {
    height: 40vh;
  }
}

@media (min-width: 992px) {
  .faq-accordion-height {
    height: 52vh;
  }
}

.bg-img {
  background: url("../assets/faqs/faqs-image.jpg") !important;
  height: 400px;
  min-width: 100vw !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.faq-text {
  padding: 11% !important;
}
.faq-button{
  height: 50px !important;
}
.faq-content-container {
  padding-bottom: 10%; 

  @media (max-width: 600px) { 
    padding-bottom: 30%; 
  }
}
.no-faqs-message {
  height: 20vh;
}

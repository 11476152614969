$primary: #1f3763;
$secondary: #f58008;
$ternary: #019cdc;

$blue_1: #fbfcff;
$blue_2: #ecf7fc;
$blue_3: #24abe3;
$blue_4: #1f3763;
$blue_5: blue;
$blue_6: #dfe2e7;
$blue_7: #e1eaf9;
$blue_8: #0b57d0;
$blue_9: #081d44;
$blue_10: #24abe3;
$blue_11: #191825;
$blue_12: #1f3763;
$blue_13: #1f3763;
$blue_14: #1181b2;
$blue_15: #8dabe142;
$blue_16: #081d44;
$blue_17: #6c757d;
$blue_18: #03040542;
$blue_19: #0faae3;
$blue_20: #0e101c;
$blue_21: #24aae2;
$blue_22: #2b71f7;
$blue_23: #8ab4f8;

$orange_1: #ed6c02;
$orange_2: #f77e0b;
$orange_3: #fa921f;

$white_1: white;
$white_2: #ffffff;
$white_3: #f1f1f1;
$white_4: #f0f3f9;

$red_1: red;
$red_2: #d63e3e;

$black_1: #0b0202;
$black_2: black;
$black_3: #313233;
$black_4: #0000001a;
$black_5: #00000080;
$black_6: #0000005b;

$gray_1: #d3d2ae;
$gray_2: #d3d2ae;
$gray_3: #706c61;
$gray_4: #ccc;
$gray_5: #a6a6a6;
$gray_6: #b3b3b3;
$gray_7: #000000;
$gray_8: #81808c;
$gray_9: #f0f3ff;
$gray_10:#3A3A3C;

$pink_1: #fff;

$green_1: #79b62fdf;


@import "./variable.scss";

.enrollbtncontainer {
  padding-right: 18%;
}

.enrollbtn {
  background-color: $blue_9 !important;
  color: $white_1;
}

.enrollbtn:hover {
  background-color: $blue_10 !important;
}

.slick {
  height: 75vh !important;
}

.programs-image-container {
  width: 100%;
  height: 82vh;
  overflow: hidden;
  .img {
    width: 100%;
    height: auto;
  }
}

.slick .slick-dots li button:before {
  font-size: 0.9375rem;
  color: $blue_9 !important;
}

.slick .slick-dots {
  z-index: 99 !important;
}

.card-slick .slick-dots li button:before {
  font-size: 0.9375rem;
  color: $white_1;
}

.achievement-card {
  position: relative;
  overflow: hidden;
}

.achievement-info {
  position: absolute;
  bottom: -1px !important;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.achievement-card:hover .achievement-info {
  opacity: 1;
}

.submenu {
  position: absolute;
  background-color: $white_1;
  box-shadow: 0 4px 8px $gray_7;
  z-index: 1000;
  top: 80%;
}

@media (max-width: 960px) {
  .submenu {
    top: 23%;
    left: -5%;
    width: 95%;
  }
}

@media (max-width: 576px) {
  .submenu {
    top: 22%;
    left: -7%;
    width: 100%;
  }
}

.input-fileds {
  background-color: $blue_18 !important;
  height: 4.7vh;
}

.input-fileds:focus {
  outline: none !important;
  border: 0;
}

.input-fileds::placeholder {
  font-size: 0.75rem;
}

.cv::placeholder {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
}

.cv {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: $pink_1;
  border: 0;
  box-sizing: border-box;
  font-size: 1rem;
  color: $black_1;
  text-align: center;
  line-height: 1.5;
  cursor: pointer;
  outline: none;
}

.textarea {
  background-color: $blue_18 !important;
  width: 20.83vw !important;
}

.textarea:focus {
  outline: none !important;
  border: 0;
}

textarea::placeholder {
  font-size: 0.75rem;
}

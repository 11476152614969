@import "./variable.scss";

.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-button {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 5px;
  border-radius: 20px;
  padding: 5px;
  border-color: $blue_5;
  background: transparent;
  text-align: center;
}

.chatbot-container {
  position: fixed;
  bottom: 5px;
  right: 8px;
  z-index: 1000;
}

.react-chatbot-kit-chat-header {
  background-color: $blue_3 !important;
  color: $white_1 !important;
}

.hvhCVC {
  color: $blue_3 !important;
  background-color: $white_1 !important;
  border: 1px solid $blue_3 !important;
}

.hvhCVC:hover {
  color: $white_1 !important;
  background-color: $blue_3 !important;
  opacity: 1 !important;
}

.fs-8 {
  font-size: 0.5rem !important;
}

.fs-9-point-5 {
  font-size: 0.59375rem !important; 
}

.fs-10 {
  font-size: 0.625rem !important;  
}

.fs-12 {
  font-size: 0.75rem !important;  
}

.fs-14 {
  font-size: 0.875rem !important;  
}

.fs-16 {
  font-size: 1rem !important;  
}

.fs-18 {
  font-size: 1.125rem !important;  
}

.fs-20 {
  font-size: 1.25rem !important;  
}

.fs-22 {
  font-size: 1.375rem !important; 
}

.fs-24 {
  font-size: 1.5rem !important;  
}

.fs-26 {
  font-size: 1.625rem !important;  
}

.fs-28 {
  font-size: 1.75rem !important; 
}

.fs-30 {
  font-size: 1.875rem !important;  
}

.fs-35 {
  font-size: 2.1875rem !important;  
}

.fs-40 {
  font-size: 2.5rem !important;  
}

.fs-45 {
  font-size: 2.8125rem !important;  
}

.fs-50 {
  font-size: 3.125rem !important;  
}

.fs-60 {
  font-size: 3.75rem !important; 
}

.fs-68 {
  font-size: 4.25rem !important;  
}

.fs-75 {
  font-size: 4.6875rem !important;  
}


.fw-900 {
  font-weight: 900 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

@import "./variable.scss";

.event-card-media {
  height: 220px;

  @media (min-width: 768px) {
    height: 220px;
  }

  @media (min-width: 1024px) {
    height: 280px;
  }
  @media (min-width: 1440px) {
    height: 300px;
  }
}

.event-card-main {
  width: 300;
  height: 300;
}
.calender-month-icon {
  color: $orange_2;
}

.event-scrlbar {
  max-height: 213px;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: white_3;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black_4;
  }
}

.event-description {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.schedule-icon {
  color: $orange_2;
}
.lacation-icon {
  color: $orange_2;
}

.color-primary {
  color: $primary;
}

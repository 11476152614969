@import url("https://fonts.googleapis.com/css2?family=Play&display=swap");

* {
  font-family: "Play" !important;
}

::-webkit-scrollbar {
  display: none;
}



@import "./variable.scss";

$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;

.page-title {
  font-size: 4.0625rem; 
  color: $white_2;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: 4.0625rem !important; 
  }
  @media screen and (min-width: $breakpoint-xs) {
    font-size: 3.125rem !important; 
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: 4.0625rem !important; 
  }
}


.map {
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    display: none;
  }

  @media (min-width: 600px) {
    display: block;
  }
}

.clear-all-btn {
  margin-right: 10px;
  background-color: $red_2;
  border-radius: 20px;
  color: $white_1;

  &:hover {
    background-color: $red_2;
  }
}

.submit-btn {
  background-color: $blue_4;
  border-radius: 20px;
  color: $white_1;

  &:hover {
    background-color: $blue_3;
  }
}

.color-blue {
  background-color: $blue_23;
}

@import "./styles/variable.scss";

.loadContainer {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100vh !important;
  z-index: 10000000 !important;
  background-color: $black_5;
  position: fixed;
  top: 0;
  left: 0;
}

.loader,
.loader:before,
.loader:after {
  background: $blue_14;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: $blue_14;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 0.375rem;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.toast-css {
  border-radius: 0px !important;
  min-height: 0 !important;
  padding: 4px !important;
  margin-bottom: 0 !important;
  align-items: center !important;
  background-color: $white_4 !important;
  box-shadow: none !important;
  width: 600px;
  opacity: 1;
}

.Toastify__toast--success {
  border-left: 4px solid $green_1 !important;
}

.Toastify__toast--error {
  border-left: 400px solid $black_6 !important;
  color: $gray_7 !important;
}

.Toastify__toast--info {
  border-left: 4px solid $blue_22 !important;
}

.toast-body {
  margin: 0 auto;
  padding: 6px !important;
}

.Toastify__toast-container--top-center {
  top: 4.3em !important;
  right: 0 !important;
  left: 35% !important;
  transform: none !important;
}

.react-toast-notifications__container {
  z-index: 10000 !important;
}

.toast-component-container {
  z-index: 100000 !important;
}

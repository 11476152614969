@import "./variable.scss";

.nav-link {
  position: relative;
  margin: 0px 15px;
}

.nav-link:after {
  content: "";
  position: absolute;
  left: 0;
  right: 100%;
  bottom: 5px;
  background: $blue_3;
  height: 2px;
  transition-property: left right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.nav-link:hover:after {
  background: $orange_3;
}

.nav-link:hover:after,
.nav-link:focus:after,
.nav-link:active:after {
  right: 0;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: $blue_3;
}

.activeLink {
  font-weight: 600 !important;
  color: $black_2 !important;
}

.image-container {
  position: relative;
}

.hover-image {
  display: none;
}

.image-container:hover .image {
  display: none;
}

.image-container:hover .hover-image {
  display: block;
}

.nav-link.activeLink:after {
  right: 0;
  background: $blue_3;
}

.solutionLink:hover {
  font-weight: 700;
}
.nav-link-hover:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  background: $orange_3;
  height: 2px;
  transition:
    right 0.3s ease-out,
    background 0.3s ease-out;
}

.nav-menu {
  display: none;
  z-index: 10000 !important;

  @media (min-width: 600px) {
    display: block;
  }

  transition:
    width 0.5s ease-in-out,
    height 0.5s ease-in-out,
    background-color 0.5s ease-in-out;
}
.nav-menu-hoverpage {
  position: fixed;
  top: 85px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
}

.nav-menucard1 {
  background-color: $white_1;
  box-shadow: 0px 4px 8px $gray_7;
  border-radius: 8px;
}

.image-container {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 251px;
  height: 100%;
  border-radius: 8px;
  padding: 8px;
  transition: background-color 0.3s ease;
}
